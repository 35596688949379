import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { managementAPI } from '../utils/api';

export interface Merchant {
  id: string;
  owner_id: string;
  name: string;
  email: string;
  phone: string; // pattern: '^\d{12}$' Mobile phone number in E.164 format.
  address: string;
  website: string;
  gst?: string; // description: Goods and Services Tax number
  country_code?: string;
}
export type CreateMerchantRequest = Omit<Merchant, 'id'>;

const useCreateMerchant = (
  options?: UseMutationOptions<void, AxiosError, CreateMerchantRequest>,
): UseMutationResult<void, AxiosError, CreateMerchantRequest> => {
  return useMutation(createMerchant, options);
};

const createMerchant = async (data: CreateMerchantRequest): Promise<void> => {
  const { data: response } = await managementAPI.post<void>('/merchants', data);
  return response;
};

export default useCreateMerchant;
