import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { managementAPI } from '../utils/api';

export interface UpdateMerchantRequest {
  callbackUrl: string;
  id: string;
}

const useUpdateMerchant = (
  options?: UseMutationOptions<void, AxiosError, UpdateMerchantRequest>,
): UseMutationResult<void, AxiosError, UpdateMerchantRequest> => {
  return useMutation(updateMerchant, options);
};

const updateMerchant = async (merchant: UpdateMerchantRequest): Promise<void> => {
  const { data: response } = await managementAPI.put<void>(`/merchants/${merchant.id}`, {
    settings: { otl_merchant_callback: merchant.callbackUrl },
  });
  return response;
};

export default useUpdateMerchant;
