import { OptionType, SelectInput } from '@bureau/components/src';
import { Stack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { TryOutVerifyPAN } from './TryOutVerifyPAN';

const options = [{ value: 'fetchPAN', label: 'Fetch PAN' }];

const selectedApiToComponent = {
  fetchPAN: TryOutVerifyPAN,
} as { [key: string]: () => ReactElement };
export const KYCTryOut = (): ReactElement => {
  const [selectedApi, setSelectedApi] = React.useState<string>('fetchPAN');
  const Component = selectedApiToComponent[selectedApi];
  return (
    <Stack align="flex-start" spacing="4">
      <Stack paddingLeft="4">
        <Text fontSize="lg">Choose option</Text>
        <SelectInput
          isClearable={false}
          width="300px"
          name="selectedApi"
          options={options}
          value={selectedApi}
          menuWidth="300px"
          onChange={value => {
            const finalValue = (value as OptionType)?.value;
            setSelectedApi(finalValue);
          }}
        />
      </Stack>
      <Stack alignSelf="stretch">
        <Component />
      </Stack>
    </Stack>
  );
};
