import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { tryOutAPI } from '../../utils/api';

interface VerifyPANTryOutData {
  pan: string;
}

type ResultType = { [key: string]: string };
const useVerifyPANTryOut = (
  options?: UseMutationOptions<ResultType, AxiosError, VerifyPANTryOutData>,
): UseMutationResult<ResultType, AxiosError, VerifyPANTryOutData> => {
  return useMutation(getMatch, options);
};

const getMatch = async (data: VerifyPANTryOutData): Promise<ResultType> => {
  const { data: response } = await tryOutAPI.post<ResultType>('/v1/kyc/fetch/pan', { ...data });
  return response;
};

export default useVerifyPANTryOut;
