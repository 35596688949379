import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC } from 'react';

export interface FormControlProps extends ChakraFormControlProps {
  name: string;
  label?: string;
  helperText?: string;
  error?: string;
}

export const FormControl: FC<FormControlProps> = (props: FormControlProps) => {
  const { children, name, label, helperText, error, isInvalid, ...rest } = props;
  const [, { error: fieldError, touched }] = useField(name);
  const errorMessage = fieldError || error;
  return (
    <ChakraFormControl isInvalid={(!!errorMessage && touched) || isInvalid} {...rest}>
      {label && (
        <FormLabel color="gray.500" fontSize="md" fontWeight="semibold" htmlFor={name}>
          {label}
        </FormLabel>
      )}
      {children}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </ChakraFormControl>
  );
};

export default FormControl;
