import { AspectRatio, Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { useOnetapMetrics } from '../queries/useOnetapMetrics';

export const MetricsSection = (): React.ReactElement => {
  const { data } = useOnetapMetrics();

  return (
    <Stack overflow="hidden">
      <AspectRatio maxW="100%">
        <Box as="iframe" src={data?.url} width="100%" height="100%" title="Metrics" allowFullScreen />
      </AspectRatio>
    </Stack>
  );
};
