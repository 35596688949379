import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { tryOutAPI } from '../../utils/api';

interface PhoneNetworkSignalsTryOutData {
  phone: string;
}

type ResultType = { [key: string]: string };
const usePhoneNetworkTryOut = (
  options?: UseMutationOptions<ResultType, AxiosError, PhoneNetworkSignalsTryOutData>,
): UseMutationResult<ResultType, AxiosError, PhoneNetworkSignalsTryOutData> => {
  return useMutation(getMatch, options);
};

const getMatch = async (data: PhoneNetworkSignalsTryOutData): Promise<ResultType> => {
  const { data: response } = await tryOutAPI.post<ResultType>('/v1/users/fetch-signals', {
    signal: ['phone-network'],
    attributes: { ...data },
  });
  return response;
};

export default usePhoneNetworkTryOut;
