import React, { ReactElement } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import AuthenticationProvider from '../authentication/AuthenticationProvider';
import ProtectedRoute from '../authentication/ProtectedRoute';
import history from '../utils/history';
import AppLanding from './AppLanding';
import Login from './Login';
import VerifiedPage from './VerifiedPage';

const AppRoutes = (): ReactElement => {
  return (
    <Router history={history}>
      <AuthenticationProvider>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/verified">
            <VerifiedPage />
          </Route>
          <ProtectedRoute path="/" component={AppLanding} />
        </Switch>
      </AuthenticationProvider>
    </Router>
  );
};

export default AppRoutes;
