import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader, PageError } from '@bureau/components';
import { Button, Link, Text } from '@chakra-ui/react';

const AuthRedirectHandler = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const data = useAuth0();
  if (data.isLoading && !data.isAuthenticated) {
    return <PageLoader>Loading</PageLoader>;
  }
  if (data.error) {
    let [errorCode, errorMessage] = data.error.message.split(':');
    if (!errorMessage) {
      errorMessage = data.error.message;
    }
    return (
      <PageError backgroundColor="gray.100">
        <Text fontSize="3xl" fontWeight="semibold">
          {errorCode === 'unverified' ? 'Welcome!' : 'Oops'}
        </Text>
        <Text fontSize="xl">{errorMessage.trimStart() || 'error'}</Text>
        <Button as={Link} href="/" alignSelf="flex-start" variant="solid" colorScheme="blue" size="lg">
          Continue
        </Button>
      </PageError>
    );
  }
  return <>{children}</>;
};

export default AuthRedirectHandler;
