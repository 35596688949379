import { InputControl } from '@bureau/components';
import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { TryOutResult } from '../TryOutResult';
import useEmailSignalTryOut from './useEmailSignalTryOut';

export const TryOutEmail = (): ReactElement => {
  const { mutateAsync, data, error } = useEmailSignalTryOut();
  return (
    <>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('E-mail is required'),
        })}
        onSubmit={values => {
          return mutateAsync(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <Flex flexWrap="wrap">
              <InputControl
                paddingLeft="4"
                paddingTop="4"
                width="200px"
                minWidth="33%"
                inputProps={{ backgroundColor: 'white' }}
                name="email"
                label="E-mail"
                placeholder="Enter e-mail address"
                isRequired
              />
            </Flex>
            <Button isLoading={isSubmitting} type="submit" marginLeft="4" marginTop="4" colorScheme="blue">
              Try it out
            </Button>
          </Form>
        )}
      </Formik>
      <TryOutResult result={data} error={error} />
    </>
  );
};
