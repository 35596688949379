import { Box, BoxProps, chakra, HTMLChakraProps } from '@chakra-ui/react';
import * as React from 'react';

const Logo = ({
  width = '24px',
  height = '',
  children,
  boxProps,
  ...props
}: HTMLChakraProps<'svg'> & {
  children?: React.ReactNode;
  boxProps?: BoxProps | undefined;
}): React.ReactElement => {
  return (
    <Box width={width} height={height} {...boxProps}>
      <chakra.svg
        aria-hidden
        viewBox="0 0 302 302"
        fill="none"
        flexShrink={0}
        css={{
          width: 'full',
          height: 'full',
        }}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.330078 19.7701C0.330078 0.160141 19.9401 0.160156 19.9401 0.160156H98.3401C117.9 0.160156 117.9 19.7202 117.9 19.7202V58.9601C117.9 58.9601 117.9 117.73 59.1301 117.73H19.9901C0.340075 117.73 0.340076 98.0801 0.340076 98.0801V19.7701H0.330078ZM0.330078 282.33V164.83C0.330078 164.83 0.33008 145.18 19.9801 145.18H59.1301C117.9 145.18 117.9 203.95 117.9 203.95V282.38C117.9 282.38 117.9 301.94 98.3401 301.94H19.9401C19.9501 301.94 0.330078 301.94 0.330078 282.33Z"
          fill="#09C2FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M204.13 0.160156C262.92 0.160156 262.92 58.9502 262.92 58.9502C262.92 58.9502 262.92 117.74 204.11 117.74C145.3 117.74 145.34 58.9702 145.34 58.9702V19.7302C145.34 19.7302 145.34 0.170166 164.9 0.170166H204.13V0.160156ZM165 301.94C145.34 301.94 145.37 282.31 145.37 282.31V203.92C145.37 145.16 204.11 145.18 204.11 145.18H223.73C223.73 145.18 302.1 145.16 302.1 223.55C302.1 301.94 223.73 301.92 223.73 301.92C223.73 301.92 184.66 301.94 165 301.94Z"
          fill="#09C2FF"
        />
        {children}
      </chakra.svg>
    </Box>
  );
};

export default Logo;
