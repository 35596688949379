import { extendTheme } from '@chakra-ui/react';

const Card = {
  // The styles all Cards have in common
  baseStyle: {
    display: 'flex',
    background: 'white',
  },
  // Two variants: rounded and smooth
  variants: {
    rounded: {
      padding: 6,
      borderRadius: 'xl',
      boxShadow: 'xl',
    },
    smooth: {
      paddingX: 6,
      paddingY: 4,
      borderRadius: 'base',
      boxShadow: 'md',
    },
  },
  // The default variant value
  defaultProps: {
    variant: 'smooth',
  },
};
const theme = extendTheme({
  fonts: {
    heading: 'Noto Sans',
    body: 'Noto Sans',
  },
  components: {
    Card,
  },
  colors: {
    gray: {
      '50': '#F9F9F9',
      '100': '#f2f2f2',
      '200': '#e8e8e8',
    },
  },
});

export default theme;
