import { FontLoader } from '@bureau/components';
import { ChakraProvider } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import AppRoutes from './pages/AppRoutes';
import theme from './utils/theme';

const App = (): ReactElement => {
  return (
    <React.StrictMode>
      <FontLoader />
      <ChakraProvider theme={theme}>
        <AppRoutes />
      </ChakraProvider>
    </React.StrictMode>
  );
};

export default App;
