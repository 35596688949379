import { InputControl } from '@bureau/components/src';
import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { TryOutResult } from '../TryOutResult';
import useVerifyPANTryOut from './useVerifyPANTryOut';

export const TryOutVerifyPAN = (): ReactElement => {
  const { mutateAsync, data, error } = useVerifyPANTryOut();
  return (
    <>
      <Formik
        initialValues={{
          pan: '',
        }}
        validationSchema={Yup.object().shape({
          pan: Yup.string().required('PAN Number is required'),
        })}
        onSubmit={values => {
          return mutateAsync(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <Flex flexWrap="wrap">
              <InputControl
                paddingLeft="4"
                paddingTop="4"
                width="200px"
                minWidth="33%"
                inputProps={{ backgroundColor: 'white' }}
                name="pan"
                label="PAN number"
                placeholder="PAN number"
                isRequired
              />
            </Flex>
            <Button isLoading={isSubmitting} type="submit" marginLeft="4" marginTop="4" colorScheme="blue">
              Try it out
            </Button>
          </Form>
        )}
      </Formik>
      <TryOutResult result={data} error={error} />
    </>
  );
};
