import useSubscriptions, { Subscription } from './useSubscriptions';

const useActiveSubscriptions = (): Subscription[] => {
  const { data: subscriptions } = useSubscriptions();
  if (!subscriptions) {
    return [];
  }
  return subscriptions.filter(subscription => {
    return ['FRESH', 'ACTIVE'].includes(subscription.status);
  });
};

export default useActiveSubscriptions;
