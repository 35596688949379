type QuickLinksType = Array<QuickLinkType>;
export type QuickLinkType = {
  title: string;
  url: string;
  external?: boolean;
};

export type ProductMapType = {
  [key: string]: {
    title: string;
    description: string;
    logo: string;
    url: string;
    quickLinks?: QuickLinksType;
    endpoints: Array<EndpointType>;
  };
};
export type EndpointType = { url: string; title: string; type: string };
export const productMap = {
  OTL: {
    title: 'One Tap Login',
    description: 'Authenticate users without SMS based OTPs.',
    logo: '/OTL.png',
    url: '/products/onetap',
    endpoints: [
      {
        url: 'https://api.bureau.id/v1',
        title: 'Endpoint',
        type: 'production',
      },
      {
        url: 'https://api.sandbox.bureau.id/v1',
        title: 'Sandbox endpoint',
        type: 'sandbox',
      },
    ],
    quickLinks: [
      {
        title: 'Documentation',
        url: 'https://docs.onetaplogin.co/',
        external: true,
      },
      {
        title: 'Credentials',
        url: '/settings/credentials',
      },
      {
        title: 'Webhooks',
        url: '/settings/webhooks',
      },
    ],
  },
  OnBoard: {
    title: 'Bureau OnBoard',
    description: 'Onboard users to your applications',
    logo: '/OnBoard.png',
    url: '/products/onboard',
    endpoints: [
      {
        url: 'https://api.bureau.id/v1',
        title: 'Endpoint',
        type: 'production',
      },
      {
        url: 'https://api.sandbox.bureau.id/v1',
        title: 'Sandbox endpoint',
        type: 'sandbox',
      },
    ],
  },
} as ProductMapType;
