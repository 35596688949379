import { Flex, FlexProps } from '@chakra-ui/react';
import * as React from 'react';

const Header = ({ children, ...rest }: FlexProps): React.ReactElement => {
  return (
    <Flex
      as="header"
      width="full"
      height={16}
      flexShrink={0}
      backgroundColor="white"
      color="black"
      fontSize="sm"
      overflow="auto"
      zIndex="overlay"
      alignItems="center"
      boxShadow="sm"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default Header;
