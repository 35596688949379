import { Input, InputGroup, InputProps, InputRightElement, Spinner } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC } from 'react';
import { FormControlProps, FormControl } from './FormControl';

export type InputControlProps = FormControlProps & {
  type?: string;
  inputProps?: InputProps & { inputRef?: React.MutableRefObject<null> };
  isLoading?: boolean;
  leftChildren?: React.ReactNode;
};

export const InputControl: FC<InputControlProps> = (props: InputControlProps) => {
  const { name, label, placeholder, type, children, isDisabled, isLoading, inputProps, leftChildren, ...rest } = props;
  const { inputRef, ...restInputProps } = inputProps || {};
  const [field] = useField(name);
  return (
    <FormControl name={name} label={label} isDisabled={isDisabled || isLoading} {...rest}>
      <InputGroup>
        {leftChildren}
        <Input
          {...field}
          {...restInputProps}
          id={name}
          type={type}
          placeholder={placeholder}
          ref={inputProps?.inputRef}
        />
        {isLoading && (
          <InputRightElement>
            <Spinner size="sm" color="blue.500" />
          </InputRightElement>
        )}
        {children}
      </InputGroup>
    </FormControl>
  );
};

export default InputControl;
