import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { tryOutAPI } from '../../utils/api';

interface SignalIPAddressTryOutData {
  ipaddress: string;
}

type ResultType = { [key: string]: string };
const useIPSignalTryOut = (
  options?: UseMutationOptions<ResultType, AxiosError, SignalIPAddressTryOutData>,
): UseMutationResult<ResultType, AxiosError, SignalIPAddressTryOutData> => {
  return useMutation(getMatch, options);
};

const getMatch = async (data: SignalIPAddressTryOutData): Promise<ResultType> => {
  const { data: response } = await tryOutAPI.post<ResultType>('/v1/users/fetch-signals', {
    signal: ['ip-address'],
    attributes: { ...data },
  });
  return response;
};

export default useIPSignalTryOut;
