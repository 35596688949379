import * as React from 'react';
import { Flex } from '@chakra-ui/react';

const Banner = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return (
    <Flex
      width="full"
      backgroundColor="gray.100"
      borderStyle="dashed"
      borderColor="gray.300"
      borderWidth="2px"
      padding={4}
    >
      {children}
    </Flex>
  );
};

export default Banner;
