import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from '@bureau/components';
import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from 'react-query';
import { setupAuth } from '../utils/api';

const queryClient = new QueryClient();

const QueryClientProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [accessToken, setAccessToken] = React.useState<string>('');

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated) {
      const getToken = async (): Promise<string> => {
        const token = await getAccessTokenSilently();
        setupAuth(token);
        setAccessToken(token);
        return token;
      };
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (!isAuthenticated || !accessToken) {
    return <PageLoader>Loading</PageLoader>;
  }
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </ReactQueryClientProvider>
  );
};

export default QueryClientProvider;
