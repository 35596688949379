import { Sidebar } from '@bureau/components';
import { As, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { MdHome, MdSettings } from 'react-icons/md';
import { Link, match as RouterMatch, matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import useSidebarHomeSubItems from './useSidebarHomeSubItems';

type SubItemType = {
  to: string;
  title: string;
};
const SidebarItem = ({
  to,
  icon,
  title,
  exact,
  subItems,
}: {
  to: string;
  icon: As<unknown>;
  exact?: boolean;
  title: string;
  subItems?: SubItemType[];
}): ReactElement => {
  const match = useRouteMatch({
    path: to,
    exact,
  });
  const location = useLocation();
  // check if any subItems match
  const subItemMatch = subItems?.find(subItem => {
    return matchPath(location.pathname, { path: subItem.to, exact: true });
  });
  return (
    <Flex
      direction="column"
      _groupHover={{ height: 'auto' }}
      height="48px"
      backgroundColor={match || subItemMatch ? 'gray.100' : ''}
      position="relative"
    >
      <Flex>
        <MatchBorder match={!!(match || subItemMatch)} />
        <ItemTitle to={to} title={title} icon={icon} match={match} />
      </Flex>
      {subItems && <SubItems subItems={subItems} />}
    </Flex>
  );
};

const SubItems = ({ subItems }: { subItems: SubItemType[] }): ReactElement => {
  return (
    <>
      {subItems.map(subItem => {
        return <SubItem key={subItem.to} subItem={subItem} />;
      })}
    </>
  );
};
const SubItem = ({ subItem }: { subItem: SubItemType }): ReactElement => {
  const { to, title } = subItem;
  const match = useRouteMatch({
    path: to,
    exact: true,
  });
  return (
    <Flex
      key={subItem.to}
      paddingX="3.5"
      paddingLeft="44px"
      justifyContent="flex-start"
      alignItems="center"
      height="48px"
      width="200px"
      as={Link}
      to={to}
      opacity={0}
      _groupHover={{ opacity: 1 }}
      _hover={{ backgroundColor: 'white' }}
      color={match ? 'blue.500' : ''}
    >
      {title}
    </Flex>
  );
};
const MatchBorder = ({ match }: { match: boolean }): ReactElement => {
  return (
    <Flex position="absolute" left="0" height="full" width="4px" background={match ? 'blue.700' : 'transparent'} />
  );
};
const ItemTitle = ({
  title,
  icon,
  to,
  match,
}: {
  title: string;
  icon: As<unknown>;
  to: string;
  match: RouterMatch | null;
}): ReactElement => {
  return (
    <Flex
      color={match?.isExact ? 'blue.500' : ''}
      paddingX="3.5"
      justifyContent="flex-start"
      alignItems="center"
      height="48px"
      width="100%"
      as={Link}
      to={to}
    >
      <Icon marginRight="2" as={icon} h={6} w={6} />
      <Text _groupHover={{ opacity: 1 }} opacity="0">
        {title}
      </Text>
    </Flex>
  );
};

export const PageSidebar = (): ReactElement => {
  const homeSubItems = useSidebarHomeSubItems();
  return (
    <Sidebar
      boxShadow="sm"
      width="12"
      role="group"
      _active={{
        width: '200px',
      }}
      position="fixed"
      left="0"
      marginRight="48px"
      zIndex="1"
      _hover={{
        width: '200px',
        boxShadow: 'lg',
      }}
      transition="0.2s ease width"
    >
      <Stack spacing={0}>
        <SidebarItem title="Home" to="/" exact icon={MdHome} subItems={homeSubItems} />
        <SidebarItem title="Settings" to="/settings" icon={MdSettings} />
      </Stack>
    </Sidebar>
  );
};
