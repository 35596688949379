import React, { ReactElement } from 'react';
import { Center, CircularProgress, HTMLChakraProps, Stack, Text } from '@chakra-ui/react';

const PageLoader = ({
  children,
  loaderSize,
  ...props
}: HTMLChakraProps<'div'> & { loaderSize?: string }): ReactElement => {
  return (
    <Center height="full" {...props}>
      <Stack align="center">
        <CircularProgress size={loaderSize} isIndeterminate />
        {children && <Text>{children}</Text>}
      </Stack>
    </Center>
  );
};
export default PageLoader;
