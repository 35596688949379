import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { managementAPI } from '../utils/api';

export interface UpdateUserRequest {
  id: string;
  name?: string;
  phone?: string; // Mobile phone number in E.164 format. pattern: '^\d{12}$' example: "919956712345"
}

const useUpdateUser = (
  options?: UseMutationOptions<void, AxiosError, UpdateUserRequest>,
): UseMutationResult<void, AxiosError, UpdateUserRequest> => {
  return useMutation(updateUser, options);
};

const updateUser = async (user: UpdateUserRequest): Promise<void> => {
  const { data: response } = await managementAPI.put<void>(`/users/${user.id}`, user);
  return response;
};

export default useUpdateUser;
