import { InputControl } from '@bureau/components/src';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { InputPhoneControl } from './InputPhoneControl';
import { TryOutResult } from './TryOutResult';
import useRiskTryOut from './useRiskTryOut';

export const RiskTryOut = (): ReactElement => {
  const { mutateAsync, data, error } = useRiskTryOut();
  return (
    <>
      <Text marginLeft="4" fontSize="lg">
        Get risk score and attributes from the details of the user
      </Text>
      <Formik
        initialValues={{
          email: '',
          phone: '',
          name: '',
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string().required('Phone is required'),
          email: Yup.string().email('Please enter a valid e-mail'),
        })}
        onSubmit={values => {
          const { phone } = values;
          return mutateAsync({ ...values, phone: phone && `91${phone}` });
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Flex flexWrap="wrap">
                <InputControl
                  paddingLeft="4"
                  paddingTop="4"
                  width="200px"
                  minWidth="33%"
                  inputProps={{ backgroundColor: 'white' }}
                  name="name"
                  label="Name"
                  placeholder="Name"
                  isRequired
                />
                <InputPhoneControl isRequired name="phone" />
                <InputControl
                  paddingLeft="4"
                  paddingTop="4"
                  width="200px"
                  minWidth="33%"
                  inputProps={{ backgroundColor: 'white' }}
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                />
              </Flex>
              <Button isLoading={isSubmitting} type="submit" marginLeft="4" marginTop="4" colorScheme="blue">
                Try it out
              </Button>
            </Form>
          );
        }}
      </Formik>
      <TryOutResult error={error} result={data} />
    </>
  );
};
