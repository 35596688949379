import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from '@bureau/components/src';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Login = (): React.ReactElement => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const handleLogin = async (): Promise<void> => {
    if (isAuthenticated) return;
    await loginWithRedirect();
  };
  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <PageLoader />;
};

export default Login;
