import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { Card, FullLogo } from '@bureau/components';
import { useAuth0 } from '@auth0/auth0-react';
import { UserForm } from './UserForm';

const AppWelcomeForm = (): ReactElement => {
  return <UserForm />;
};

const AppWelcome = (): ReactElement => {
  const { user } = useAuth0();
  if (!user) {
    // TODO: why is this needed?
    return <Redirect to="/login" />;
  }
  return (
    <VStack height="full">
      {/* <FullLogo width="240px" /> */}
      <Card marginTop={[0, 0, '120px']} width="100%" maxWidth="600px">
        <Stack padding={4} spacing={4}>
          <FullLogo width="140px" />
          <Heading>
            <Stack direction="row" align="baseline">
              <Text>Welcome!</Text>
            </Stack>
          </Heading>
          <Stack spacing={0}>
            <Text size="md">You have signed up with {user.email}.</Text>
            <Text size="md">Please finish filling the following details to complete registration!</Text>
          </Stack>
          <AppWelcomeForm />
        </Stack>
      </Card>
    </VStack>
  );
};

export default AppWelcome;
