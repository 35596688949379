import React, { ReactElement } from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const FormError = ({ formError }: { formError: string | string[] | null }): ReactElement => {
  if (!formError) {
    return <></>;
  }
  if (typeof formError === 'string') {
    return <Text color="red.700">{formError}</Text>;
  }
  return (
    <Stack>
      {formError.map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={index} color="red.700">
          {error}
        </Text>
      ))}
    </Stack>
  );
};
