import { PageLoader } from '@bureau/components/src';
import { HStack, Stack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import EmptyState from '../components/EmptyState';
import { MerchantEmptyState } from '../home/MerchantEmptyState';
import EndpointsList from '../product/EndpointsList';
import { ProductInfo } from '../product/ProductInfo';
import { ProductLogo } from '../product/ProductLogo';
import QuickLinks from '../product/QuickLinks';
import useMe from '../queries/useMe';
import useSubscriptions from '../queries/useSubscriptions';
import { MetricsSection } from './MetricsSection';
import { SusbscriptionSection } from './SusbscriptionSection';

const OneTapLoginProduct = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>One Tap Login - Bureau Dashboard</title>
      </Helmet>
      <Stack>
        <HStack width="100%" justifyContent="space-between">
          <HStack spacing="6">
            <ProductLogo product="OTL" />
            <Stack>
              <ProductInfo product="OTL" />
              <EndpointsList product="OTL" />
            </Stack>
          </HStack>
          <QuickLinks product="OTL" />
        </HStack>
        <ProductSection />
      </Stack>
    </>
  );
};

const ProductSection = (): ReactElement => {
  const { data, status: merchantStatus } = useMe();
  const { merchant } = data || {};
  const { data: subscriptions, status, error } = useSubscriptions();
  if (status === 'loading') {
    return <PageLoader>Loading</PageLoader>;
  }
  if (!merchant && merchantStatus === 'success') {
    return <MerchantEmptyState />;
  }
  if (status === 'error' && error && (error as AxiosError).response?.status === 404) {
    return <EmptyState>No plans found</EmptyState>;
  }
  if (status === 'error' && error) {
    return <EmptyState>{error.message}</EmptyState>;
  }
  if (!subscriptions) {
    return <EmptyState>No plans found</EmptyState>;
  }

  return (
    <>
      <SusbscriptionSection />
      <MetricsSection />
    </>
  );
};

export default OneTapLoginProduct;
