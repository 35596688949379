import React, { ReactElement } from 'react';
import { Stack } from '@chakra-ui/react';
import { Endpoint } from '../components/Endpoint';
import { productMap } from '../home/productUtil';

const EndpointsList = ({ product }: { product: string }): ReactElement => {
  const { endpoints } = productMap[product];
  return (
    <Stack spacing={3} direction="column" flexGrow={1}>
      <Stack
        direction={['column', 'column', 'column', 'row']}
        align={['flex-start', 'flex-start', 'flex-start', 'center']}
        spacing={['2', '2', '2', '8']}
      >
        {endpoints.map(endpoint => (
          <Endpoint key={endpoint.type} endpoint={endpoint} />
        ))}
      </Stack>
    </Stack>
  );
};

export default EndpointsList;
