import React, { ReactElement } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

const Sidebar = (props: FlexProps): ReactElement => {
  return (
    <Flex
      height="full"
      as="aside"
      direction="column"
      w={12}
      bg="white"
      color="black"
      fontSize="sm"
      overflow="auto"
      zIndex="base"
      flexShrink={0}
      {...props}
    />
  );
};

export default Sidebar;
