import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { productMap } from '../home/productUtil';

export const ProductInfo = ({ product }: { product: string }): React.ReactElement => {
  const { title, description } = productMap[product];
  return (
    <Stack direction="row" align="center" spacing={4}>
      <Stack spacing={0}>
        <Text fontSize="3xl">{title}</Text>
        <Text color="gray.500" fontSize="lg">
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};
