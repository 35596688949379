import { HStack, Progress, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { Step } from './Step';
import { NavigationType, StepType } from './useSteps';

export interface StepsProps {
  steps: Array<StepType>;
  activeStep: number;
  navigation: NavigationType;
  children?: React.ReactElement;
}

const StepProgress = ({ totalSteps, activeStep }: { totalSteps: number; activeStep: number }): React.ReactElement => {
  const progress = activeStep / totalSteps;
  const halfStep = (1 / totalSteps / 2) * 100;
  return <Progress flexShrink={0} height="4px" value={progress * 100 + halfStep} />;
};
const Steps = (props: StepsProps): React.ReactElement => {
  const { steps, activeStep, navigation, children } = props;
  return (
    <Stack height="full">
      <HStack width="full" spacing={0}>
        {steps.map((step, index) => {
          return (
            <Step
              navigation={navigation}
              key={step.label}
              label={step.label}
              stepIndex={index}
              activeStep={activeStep}
            />
          );
        })}
      </HStack>
      <StepProgress totalSteps={steps.length} activeStep={activeStep} />
      {steps[activeStep].content({ navigation })}
      {children}
    </Stack>
  );
};

export { Steps };
