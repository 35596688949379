import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import BureauOnboardProduct from '../onboard/BureauOnboardProduct';
import OneTapLoginProduct from '../onetap/OneTapLoginProduct';
import NotFound from '../pages/NotFound';
import PageWrap from '../pages/PageWrap';

const productIdSlugs = ['onetap', 'onboard'];

const ProductPage = (): ReactElement => {
  const { productId } = useParams<{ productId: string }>();
  if (!productIdSlugs.includes(productId)) {
    return <NotFound />;
  }

  if (productId === 'onetap') {
    return <OneTapLoginProduct />;
  }
  if (productId === 'onboard') {
    return <BureauOnboardProduct />;
  }
  return <>{productId}</>;
};
const ProductLanding = (): ReactElement => {
  const { path } = useRouteMatch();
  return (
    <PageWrap>
      <Switch>
        <Route exact path={path}>
          <Redirect to="/products/onetap" />;
        </Route>
        <Route path={`${path}/:productId`}>
          <ProductPage />
        </Route>
      </Switch>
    </PageWrap>
  );
};

export default ProductLanding;
