import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { metricsAPI } from '../utils/api';

type OnetapMetricType = { url: string };

export const useOnetapMetrics = (): UseQueryResult<OnetapMetricType, AxiosError> => {
  return useQuery<OnetapMetricType, AxiosError, OnetapMetricType>(['metrics'], fetchMetrics(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

function fetchMetrics() {
  return () => {
    return metricsAPI.get<OnetapMetricType>(`/v1/onetaplogin/dashboardmain`).then(res => {
      return res.data;
    });
  };
}
