import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { InputPhoneControl } from '../InputPhoneControl';
import { TryOutResult } from '../TryOutResult';
import usePhoneNetworkTryOut from './usePhoneNetworkTryOut';

export const TryOutPhoneNetwork = (): ReactElement => {
  const { mutateAsync, data, error } = usePhoneNetworkTryOut();
  return (
    <>
      <Formik
        initialValues={{
          phone: '',
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string().required('Phone is required'),
        })}
        onSubmit={({ phone }) => {
          return mutateAsync({ phone: phone && `91${phone}` });
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <Flex flexWrap="wrap">
              <InputPhoneControl
                paddingLeft="4"
                paddingTop="4"
                width="200px"
                minWidth="33%"
                inputProps={{ backgroundColor: 'white' }}
                name="phone"
                label="Phone"
                placeholder="Enter phone number"
                isRequired
              />
            </Flex>
            <Button isLoading={isSubmitting} type="submit" marginLeft="4" marginTop="4" colorScheme="blue">
              Try it out
            </Button>
          </Form>
        )}
      </Formik>
      <TryOutResult result={data} error={error} />
    </>
  );
};
