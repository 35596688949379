import { Card } from '@bureau/components';
import { Stack, Text, StackDirection, HStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import useMe from '../../queries/useMe';

const MerchantCard = (): ReactElement => {
  const { data, status } = useMe();
  const { merchant } = data || {};

  if (!merchant && status === 'success') {
    return <></>;
  }
  return (
    <Card minWidth="sm">
      <Stack>
        <Text fontSize="lg" fontWeight="semibold">
          Business details
        </Text>
        <Stack>
          <DataDisplay label="ID" text={merchant?.id} />
          <DataDisplay label="Name" text={merchant?.name} />
          <DataDisplay label="Phone" text={merchant?.phone} />
          <DataDisplay label="E-mail" text={merchant?.email} />
          <DataDisplay label="Website" text={merchant?.website} />
          <DataDisplay label="Country" text={merchant?.country_code} />
          <DataDisplay label="Address" text={merchant?.address} />
          <DataDisplay label="GST" text={merchant?.gst} />
        </Stack>
      </Stack>
    </Card>
  );
};

const DataDisplay = ({
  text,
  label,
  direction = 'row',
}: {
  text: string | undefined;
  label: string;
  direction?: StackDirection;
}): ReactElement => {
  if (!text) {
    return <></>;
  }
  return (
    <Stack direction={direction}>
      <Text color="gray.500" fontWeight="semibold">
        {label}
      </Text>
      <Text>{text}</Text>
    </Stack>
  );
};
const ProfileSection = (): ReactElement => {
  const { data } = useMe();
  const { user } = data || {};
  return (
    <HStack spacing={6} align="flex-start">
      <Card minWidth="sm">
        <Stack>
          <Text fontSize="lg" fontWeight="semibold">
            User details
          </Text>
          <Stack>
            <DataDisplay label="Name" text={user?.name} />
            <DataDisplay label="E-mail" text={user?.email} />
            <DataDisplay label="Phone" text={user?.phone} />
          </Stack>
        </Stack>
      </Card>
      <MerchantCard />
    </HStack>
  );
};

export default ProfileSection;
