import { CopyButton } from '@bureau/components';
import {
  Stack,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TextProps,
  Icon,
} from '@chakra-ui/react';
import React, { ReactElement, ReactNode } from 'react';
import CsvDownload from 'react-csv-downloader';
import { MdAdd } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import useCreateCredential from '../queries/useCreateCredential';
import useMe from '../queries/useMe';

const DownloadAPIDetails = ({
  clientId,
  apiKey,
}: {
  clientId: string | undefined;
  apiKey: string | undefined;
}): ReactElement => {
  if (!clientId || !apiKey) {
    return <></>;
  }
  const csvData = [
    {
      id: clientId,
      apiKey,
    },
  ];
  const csvColumns = [
    {
      id: 'id',
      displayName: 'Client ID',
    },
    {
      id: 'apiKey',
      displayName: 'API Key',
    },
  ];
  return (
    <CsvDownload filename="Bureau API Key" datas={csvData} columns={csvColumns}>
      <Button variant="link" color="blue.500">
        Download key details
      </Button>
    </CsvDownload>
  );
};

const CopyText = ({ text, ...rest }: Omit<TextProps, 'text'> & { text: string | undefined }): ReactElement => {
  if (!text) {
    return <></>;
  }
  return (
    <Stack direction="row" align="center">
      <Text fontSize="md" {...rest}>
        {text}
      </Text>
      <CopyButton text={text} />
    </Stack>
  );
};
const APIKeyModal = ({
  isOpen,
  onClose,
  clientId,
  apiKey,
}: {
  isOpen: boolean;
  onClose: () => void;
  clientId: string | undefined;
  apiKey: string | undefined;
}): ReactElement => {
  const { onOpen: onConfirmOpen, onClose: onConfirmClose, isOpen: isConfirmDialogOpen } = useDisclosure();
  return (
    <Modal isOpen={isOpen} onClose={() => onConfirmOpen()}>
      <ModalOverlay />
      <ModalContent maxWidth="lg">
        <ModalHeader>New Key Generated</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={1}>
            <Stack spacing={0}>
              <Text color="gray.500" fontWeight="semibold">
                Client ID
              </Text>
              <CopyText maxWidth="2xs" text={clientId} isTruncated />
            </Stack>
            <Stack spacing={0}>
              <Text color="gray.500" fontWeight="semibold">
                API Key
              </Text>
              <CopyText maxWidth="2xs" isTruncated text={apiKey} />
            </Stack>
          </Stack>
          <ConfirmModal
            isOpen={isConfirmDialogOpen}
            onClose={() => onConfirmClose()}
            onCloseAll={() => {
              onConfirmClose();
              onClose();
            }}
          />
        </ModalBody>
        <ModalFooter padding={4} justifyContent="space-between" alignItems="center">
          <DownloadAPIDetails clientId={clientId} apiKey={apiKey} />
          <Button colorScheme="blue" onClick={() => onConfirmOpen()}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ConfirmModal = ({
  isOpen,
  onCloseAll,
  onClose,
}: {
  isOpen: boolean;
  onCloseAll: () => void;
  onClose: () => void;
}): ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Are you sure you have saved the key details? This is the last time we will show you these details.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={() => onCloseAll()}>
            Yes, I&apos;m sure
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const CreateCredentialButton = ({ children }: { children: ReactNode }): ReactElement => {
  const { data } = useMe();
  const { merchant } = data || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const [apiKey, setApiKey] = React.useState<string | undefined>();
  const [clientId, setClientId] = React.useState<string | undefined>();
  const { mutateAsync, isLoading } = useCreateCredential(merchant?.id, {
    onSuccess: createdCredential => {
      const { api_key, id } = createdCredential;
      onOpen();
      setApiKey(api_key);
      setClientId(id);
    },
  });

  return (
    <>
      <Button
        isLoading={isLoading}
        size="md"
        colorScheme="blue"
        variant="solid"
        leftIcon={<Icon as={MdAdd} boxSize="24px" />}
        onClick={() => mutateAsync({ tenancy: 'PRODUCTION' })}
      >
        {children}
      </Button>
      <APIKeyModal
        isOpen={isOpen}
        onClose={() => {
          queryClient.refetchQueries('credentials');
          onClose();
        }}
        clientId={clientId}
        apiKey={apiKey}
      />
    </>
  );
};
