import { useQuery, UseQueryResult } from 'react-query';
import { managementAPI } from '../utils/api';
import { User } from './user.types';
import { Merchant } from './merchant.types';
import { MerchantSettings } from './merchantSettings.types';

export type MeResponse = {
  user: User;
  merchant: Merchant | null;
  settings: MerchantSettings;
};

const useMe = (): UseQueryResult<MeResponse, Error> => {
  return useQuery<MeResponse, Error, MeResponse>('me', fetchUser(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

function fetchUser() {
  return () => {
    return managementAPI.get<MeResponse>('/me').then(res => res.data);
  };
}

export default useMe;
