import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { MerchantEmptyState } from '../home/MerchantEmptyState';
import useMe from '../queries/useMe';
import CredentialsSection from '../settings/CredentialsSection';
import ProfileSection from '../settings/profile/ProfileSection';
import WebhooksSections from '../settings/WebhooksSection';
import NotFound from './NotFound';
import PageWrap from './PageWrap';

const tabsData = [
  {
    id: 'credentials',
    label: 'Credentials',
    Component: CredentialsSection,
  },
  {
    id: 'profile',
    label: 'Profile',
    Component: ProfileSection,
  },
  {
    id: 'webhooks',
    label: 'Webhooks',
    Component: WebhooksSections,
  },
];

const idArray = ['credentials', 'profile', 'webhooks'];
const findTabIndex = (settingId: string): number => {
  return idArray.findIndex(value => value === settingId);
};
const MerchantNudge = (): ReactElement => {
  const { data, status } = useMe();
  const { merchant } = data || {};

  if (!merchant && status === 'success') {
    return <MerchantEmptyState />;
  }
  return <></>;
};
const Settings = (): ReactElement => {
  const match = useRouteMatch<{ settingId?: string }>('/settings/:settingId');
  const history = useHistory();
  const handleTabsChange = (index: number): void => {
    history.push(`/settings/${idArray[index]}`);
  };
  if (!match || !match.params.settingId) {
    return <Redirect to="/settings/credentials" />;
  }
  const tabIndex = findTabIndex(match.params.settingId);
  if (tabIndex === -1) {
    return <NotFound />;
  }
  return (
    <PageWrap heading="Settings">
      <Helmet title="Settings - Bureau Dashboard" />
      <MerchantNudge />
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {tabsData.map((tab, index) => (
            <Tab key={tab.id}>{tab.label}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabsData.map((tab, index) => (
            <TabPanel paddingX={0} key={tab.id}>
              <tab.Component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageWrap>
  );
};

export default Settings;
