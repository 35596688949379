import { FormControlProps, OptionType, FormControl } from '@bureau/components';
import { Flex } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC, ReactElement, useState } from 'react';
import { CountrySelectorInput } from './CountrySelectorInput';
import { PhoneInput } from './PhoneInput';

export type PhoneControlProps = FormControlProps & {
  countryCodeFieldName?: string;
};

const CountrySelect = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value: string;
  onChange: (value: string) => void;
}): ReactElement => {
  const [, , { setValue }] = useField(name);
  type NewType = OptionType<string>;

  const handleCountryCodeChange = (data: { value: string } | null): void => {
    if (data) {
      setValue(data.value);
      onChange(data.value);
    }
  };
  return (
    <CountrySelectorInput
      value={value}
      // one of the only two places with ts-ignore
      // due to the mess with react-select types
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onChange={handleCountryCodeChange}
    />
  );
};

export const PhoneControl: FC<PhoneControlProps> = (props: PhoneControlProps) => {
  const { name, label, children, countryCodeFieldName, ...rest } = props;
  const [field, , { setValue }] = useField(name);
  const [countryCode, setCountryCode] = useState<string>('IN');
  const handlePhoneNumberChange = (value: string): void => {
    setValue(value);
  };
  const handleCountryCodeChange = (value: string): void => {
    setCountryCode(value);
    handlePhoneNumberChange('');
  };
  return (
    <FormControl name={name} label={label} {...rest}>
      <Flex direction="row">
        {countryCodeFieldName && (
          <CountrySelect value={countryCode} onChange={handleCountryCodeChange} name={countryCodeFieldName} />
        )}
        <PhoneInput {...field} countryCode={countryCode} id={name} onChange={handlePhoneNumberChange} />
      </Flex>
    </FormControl>
  );
};

export default PhoneControl;
