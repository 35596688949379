import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { tryOutAPI } from '../utils/api';

export interface RiskTryOutRequest {
  name?: string;
  phone: string;
  email: string;
  accountEvent?: string;
  otlSessionID?: string;
}

type ResultType = { [key: string]: string };
const useRiskTryOut = (
  options?: UseMutationOptions<ResultType, AxiosError, RiskTryOutRequest>,
): UseMutationResult<ResultType, AxiosError, RiskTryOutRequest> => {
  return useMutation(getMatch, options);
};

const getMatch = async (data: RiskTryOutRequest): Promise<ResultType> => {
  const { data: response } = await tryOutAPI.post<ResultType>('/v2/users/risk-score', {
    attributes: { ...data, accountEvent: 'create' },
  });
  return response;
};

export default useRiskTryOut;
