import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { managementAPI } from '../utils/api';
import { Credential, TenancyType } from './useCredentials';

export type CreateCredentialRequest = {
  tenancy: TenancyType;
};
type CreatedCredential = Credential & { api_key: string };

const useCreateCredential = (
  merchant_id: string | undefined,
  options?: UseMutationOptions<CreatedCredential, AxiosError, CreateCredentialRequest>,
): UseMutationResult<CreatedCredential, AxiosError, CreateCredentialRequest> => {
  return useMutation(createCredential(merchant_id), options);
};

const createCredential = (merchant_id: string | undefined) => {
  return async (data: CreateCredentialRequest): Promise<CreatedCredential> => {
    const { data: response } = await managementAPI.post<CreatedCredential>(
      `/merchants/${merchant_id}/credentials`,
      data,
    );
    return response;
  };
};

export default useCreateCredential;
