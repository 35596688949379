import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { tryOutAPI } from '../utils/api';

export interface MatchTryOutRequest {
  name: string;
  email: string;
  phone: string;
  dob?: string;
}

type ResultType = { [key: string]: string };
const useMatchTryOut = (
  options?: UseMutationOptions<ResultType, AxiosError, MatchTryOutRequest>,
): UseMutationResult<ResultType, AxiosError, MatchTryOutRequest> => {
  return useMutation(MatchTryOut, options);
};

const MatchTryOut = async (data: MatchTryOutRequest): Promise<ResultType> => {
  const { data: response } = await tryOutAPI.post<ResultType>('/v1/users/match', { attributes: { ...data } });
  return response;
};

export default useMatchTryOut;
