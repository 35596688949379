import { PageLoader } from '@bureau/components/src';
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { MerchantEmptyState } from '../home/MerchantEmptyState';
import useMe from '../queries/useMe';
import { KYCTryOut } from './kyc/KYCTryOut';
import { MatchTryOut } from './MatchTryOut';
import { RiskTryOut } from './RiskTryOut';
import { SignalsTryOut } from './signals/SignalsTryOut';

type FieldType = {
  id: string;
  label: string;
  required?: boolean;
};
type TryOutData = {
  [id: string]: {
    fields: FieldType[];
    title: string;
    id: string;
    description: string;
  };
};

const tabsData = [
  {
    id: 'match',
    label: 'Match',
    Component: MatchTryOut,
  },
  {
    id: 'signals',
    label: 'Fetch Signals',
    Component: SignalsTryOut,
  },
  {
    id: 'risk',
    label: 'Risk',
    Component: RiskTryOut,
  },
  {
    id: 'kyc',
    label: 'KYC',
    Component: KYCTryOut,
  },
];

export const TryOutSection = (): ReactElement => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabsChange = (index: number): void => {
    setTabIndex(index);
  };
  const { data, status: merchantStatus } = useMe();
  const { merchant } = data || {};
  if (merchantStatus === 'loading') {
    return <PageLoader>Loading</PageLoader>;
  }
  if (!merchant && merchantStatus === 'success') {
    return <MerchantEmptyState />;
  }
  return (
    <Stack spacing="4">
      <Text fontSize="2xl" fontWeight="500">
        Try it out
      </Text>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {tabsData.map((tab, index) => (
            <Tab key={tab.id}>{tab.label}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabsData.map((tab, index) => (
            <TabPanel paddingX={0} key={tab.id}>
              <tab.Component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  );
};
