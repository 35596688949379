import useMe from './useMe';

const useProductList = (): Array<string> => {
  const { data } = useMe();
  const { settings } = data || {};
  const { products_allowed } = settings || {};
  let productList = ['OTL'];
  if (products_allowed?.all || products_allowed?.fetch_signals || products_allowed?.risk_score) {
    productList = ['OTL', 'OnBoard'];
  }

  return productList;
};

export default useProductList;
