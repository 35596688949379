import { Avatar, Box, Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Logo } from '@bureau/components';
import Header from '../navigation/Header';
import useMe from '../queries/useMe';

export const PageHeader = (): ReactElement => {
  const { data } = useMe();
  const { user } = data || {};
  const { logout } = useAuth0();
  return (
    <Header>
      <Flex width="48px" alignItems="center" justifyContent="center">
        <Logo />
      </Flex>
      <Box marginLeft="auto" marginRight={2}>
        <Menu>
          <MenuButton>
            <Avatar name={user?.name} size="sm" />
          </MenuButton>
          <MenuList>
            <MenuGroup title={`Welcome, ${user?.name}`}>
              <MenuItem onClick={(): void => logout({ returnTo: `${window.location.origin}/`, federated: false })}>
                Logout
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
    </Header>
  );
};
