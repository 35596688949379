import { InputControl } from '@bureau/components';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import { InputPhoneControl } from './InputPhoneControl';
import { TryOutResult } from './TryOutResult';
import useMatchTryOut from './useMatchTryOut';

export const MatchTryOut = (): ReactElement => {
  const { mutateAsync, data, error } = useMatchTryOut();
  return (
    <>
      <Text marginLeft="4" fontSize="lg">
        Either phone number or email is required
      </Text>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
        }}
        onSubmit={values => {
          const { phone } = values;
          return mutateAsync({ ...values, phone: phone && `91${phone}` });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Flex flexWrap="wrap">
              <InputControl
                paddingLeft="4"
                paddingTop="4"
                width="200px"
                minWidth="33%"
                inputProps={{ backgroundColor: 'white' }}
                name="name"
                label="Name"
                placeholder="Name"
                isRequired
              />
              <InputPhoneControl name="phone" />
              <InputControl
                paddingLeft="4"
                paddingTop="4"
                width="200px"
                minWidth="33%"
                inputProps={{ backgroundColor: 'white' }}
                name="email"
                label="E-mail"
                placeholder="E-mail"
              />
            </Flex>
            <Button isLoading={isSubmitting} type="submit" marginLeft="4" marginTop="4" colorScheme="blue">
              Try it out
            </Button>
          </Form>
        )}
      </Formik>
      <TryOutResult result={data} error={error} />
    </>
  );
};
