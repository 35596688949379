import { Heading } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = (): React.ReactElement => (
  <div>
    <Heading>404 - Not Found!</Heading>
    <Link to="/">Go Home</Link>
  </div>
);

export default NotFound;
