import { useQuery, UseQueryResult } from 'react-query';
import { managementAPI } from '../utils/api';
import useMe from './useMe';

export interface Subscription {
  id: string; // format: uuid
  merchant_id: string; // format: uuid
  product_name: 'ONE_TAP_LOGIN'; // enum: ONE_TAP_LOGIN
  type: string; // description: PREPAID/POSTPAID example: PREPAID
  price: number; // description: Total cost of the subscription (PREPAID subscription)
  quota: number; // description: max limit on number of API calls per billing cycle (PREPAID subscription)
  usage: number; // description: usage
  min_commitment: number; // description: minimum bill per billing cycle (PREPAID subscription)
  created_at: string; // format: date-time
  updated_at: string; // format: date-time
  valid_upto: string; // format: date-time (PREPAID subscription)
  price_per_unit: number; // (POSTPAID subscription)
  status: 'ACTIVE' | 'FRESH' | 'EXPIRED' | 'EXHAUSTED';
  currency: string; // ISO 4217 currency code
}

const useSubscriptions = (): UseQueryResult<Subscription[], Error> => {
  const { data } = useMe();
  const { merchant } = data || {};
  return useQuery<Subscription[], Error>(['Subscriptions', merchant?.id], getFetchSubscriptions(merchant?.id), {
    retry: false,
    enabled: !!merchant?.id,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

const getFetchSubscriptions = (merchant_id: string | undefined) => {
  return () => {
    return managementAPI.get<Subscription[]>(`merchants/${merchant_id}/subscriptions`).then(res => res.data);
  };
};

export default useSubscriptions;
