import { InputControl } from '@bureau/components';
import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { TryOutResult } from '../TryOutResult';
import useIPSignalTryOut from './useIPSignalTryOut';

export const TryOutIPAddress = (): ReactElement => {
  const { mutateAsync, data, error } = useIPSignalTryOut();
  return (
    <>
      <Formik
        initialValues={{
          ipaddress: '',
        }}
        validationSchema={Yup.object().shape({
          ipaddress: Yup.string().required('ipaddress is required'),
        })}
        onSubmit={values => {
          return mutateAsync(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <Flex flexWrap="wrap">
              <InputControl
                paddingLeft="4"
                paddingTop="4"
                width="200px"
                minWidth="33%"
                inputProps={{ backgroundColor: 'white' }}
                name="ipaddress"
                label="IP Address"
                placeholder="Enter IP address"
                isRequired
              />
            </Flex>
            <Button isLoading={isSubmitting} type="submit" marginLeft="4" marginTop="4" colorScheme="blue">
              Try it out
            </Button>
          </Form>
        )}
      </Formik>
      <TryOutResult result={data} error={error} />
    </>
  );
};
