import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { productMap } from '../home/productUtil';

export const ProductCard = ({ product }: { product: string }): React.ReactElement => {
  const { title, description, logo, url } = productMap[product];
  return (
    <Stack
      direction="column"
      align="center"
      spacing={4}
      width="300px"
      background="white"
      padding="20px"
      borderRadius="md"
      shadow="md"
      as={Link}
      to={url}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        backgroundColor="gray.100"
        borderRadius="full"
        height="120px"
        width="120px"
        padding={4}
        flexShrink={0}
      >
        <Image height="80%" src={logo} />
      </Flex>
      <Stack textAlign="center" spacing={0}>
        <Text fontSize="xl">{title}</Text>
        <Text color="gray.500" fontSize="lg">
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};
