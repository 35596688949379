import { useAuth0 } from '@auth0/auth0-react';
import { FullLogo } from '@bureau/components/src';
import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const useURLQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};
/// https://bureau-dashboard.netlify.app/verified?supportSignUp=true&supportForgotPassword=true&message=Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.&success=true&code=success

const VerifiedPage = (): ReactElement => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const query = useURLQuery();
  const message = 'Your email was verified. You can continue using the application.';
  const isSuccessful = query.get('success') === 'true';
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Flex alignItems="flex-start" justifyContent="center" height="full" backgroundColor="gray.50">
      <FullLogo boxProps={{ marginTop: 14, marginLeft: 6 }} width="180px" marginLeft={4} />
      <Stack marginTop={12} spacing={10} marginLeft={10} marginRight="auto" maxWidth="900px">
        <Text fontSize="4xl" fontWeight="semibold">
          {isSuccessful ? 'Success' : 'Oops'}
        </Text>
        <Text fontSize="3xl">{isSuccessful ? message : 'Invalid'}</Text>
        <Button alignSelf="flex-start" variant="solid" colorScheme="blue" size="lg" onClick={() => loginWithRedirect()}>
          Continue
        </Button>
      </Stack>
    </Flex>
  );
};

export default VerifiedPage;
