import { Box, Stack, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { prettyPrintJson } from 'pretty-print-json';
import 'pretty-print-json/dist/pretty-print-json.css';
import React, { ReactElement } from 'react';

const ErrorMessage = ({ error }: { error: AxiosError }): ReactElement => {
  const errorData = error.response?.data as { [key: string]: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let errorJSON = error.toJSON() as { [key: string]: any };
  // strip token and stack trace
  errorJSON = {
    ...errorJSON,
    stack: undefined,
    config: { ...errorJSON.config, headers: { ...errorJSON.config.headers, AuthorizationToken: 'HIDDEN' } },
  };
  return (
    <Stack>
      <Text fontSize="xl">Error</Text>
      <JSONRender json={errorData || errorJSON} />
    </Stack>
  );
};
export const TryOutResult = ({
  id,
  result,
  error,
}: {
  id?: string;
  error?: AxiosError | null;
  result?: { [key: string]: string };
}): ReactElement => {
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!result) {
    return <></>;
  }
  return (
    <Stack marginLeft="4" marginTop="4">
      <Text fontSize="xl" fontWeight="500">
        Result
      </Text>
      <JSONRender json={result} />
    </Stack>
  );
};

const JSONRender = ({ json }: { json: { [key: string]: string } }): ReactElement => {
  return (
    <Box
      borderRadius="md"
      backgroundColor="white"
      padding="3"
      as="pre"
      whiteSpace="pre-wrap"
      dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(json) }}
    />
  );
};
