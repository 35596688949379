import { HStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import PageWrap from '../pages/PageWrap';
import { ProductCard } from '../product/ProductCard';
import useProductList from '../queries/useProductList';

const Home = (): ReactElement => {
  const productList = useProductList();
  if (productList.length === 1 && productList[0] === 'OTL') {
    return <Redirect to="/products/onetap" />;
  }
  return (
    <PageWrap heading="Welcome!">
      <Helmet title="Bureau Dashboard" />

      <HStack spacing={10}>
        {productList.map(product => (
          <ProductCard key={product} product={product} />
        ))}
      </HStack>
    </PageWrap>
  );
};

export default Home;
