import * as React from 'react';
import { Global, css } from '@emotion/react';

const FontLoader = (): React.ReactElement => (
  <>
    <Global
      styles={css`
        @font-face {
          font-family: 'Noto Sans';
          font-weight: 400;
          src: url('/fonts/NotoSans-Regular.woff2') format('woff2'), url('/fonts/NotoSans-Regular.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-weight: 500;
          src: url('/fonts/NotoSans-SemiBold.woff2') format('woff2'),
            url('/fonts/NotoSans-SemiBold.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-weight: 600;
          src: url('/fonts/NotoSans-SemiBold.woff2') format('woff2'),
            url('/fonts/NotoSans-SemiBold.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-weight: 700;
          src: url('/fonts/NotoSans-Bold.woff2') format('woff2'), url('/fonts/NotoSans-Bold.woff') format('woff');
        }
      `}
    />
  </>
);
export default FontLoader;
