import { SelectInput, SelectInputProps } from '@bureau/components';
import { Stack, Text } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';
import ReactCountryFlag from 'react-country-flag';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import { components, SingleValueProps } from 'react-select';

const countryCodes = getCountries();
const countryOptions = countryCodes
  .map(countryCode => {
    const countryName: string = (en as { [key: string]: string })[countryCode];
    const diallingCode = getCountryCallingCode(countryCode);
    return { value: countryCode, label: `${countryName} (+${diallingCode})`, diallingCode };
  })
  .sort((optionA, optionB) => (optionA.label > optionB.label ? 1 : -1));

export type CountrySelectorInputProps = Omit<SelectInputProps, 'options'> & {
  onChange: ({ value }: { value: string }) => void;
  value: string;
};

const cdnUrl = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/';
type OptionType = { value: string; label: string; diallingCode: string };
type SingleValuePropsType = SingleValueProps<OptionType>;
const SingleValue: FC<SingleValuePropsType> = ({ data, ...props }: SingleValuePropsType): ReactElement => {
  const { value: countryCode, diallingCode } = data;
  return (
    <components.SingleValue {...props} data={data}>
      <Stack direction="row" spacing={1} align="center">
        <ReactCountryFlag
          style={{ fontSize: '20px' }}
          countryCode={countryCode}
          aria-label={`${countryCode} Flag`}
          title={`${countryCode} Flag`}
          cdnUrl={cdnUrl}
        />
        <Text>{`+${diallingCode}`}</Text>
      </Stack>
    </components.SingleValue>
  );
};
const overrideComponents = { SingleValue, DropdownIndicator: () => null };

export const CountrySelectorInput: FC<CountrySelectorInputProps> = ({
  value,
  onChange,
  isClearable = false,
  ...props
}: CountrySelectorInputProps) => {
  return (
    <SelectInput
      {...props}
      onChange={onChange}
      isClearable={isClearable}
      value={value}
      options={countryOptions}
      width="80px"
      staticContainer
      size="small"
      // one of the only two places with ts-ignore
      // due to the mess with react-select types
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      components={overrideComponents}
      styles={{
        control: () => ({
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          height: '100%',
          borderRight: 0,
        }),
      }}
    />
  );
};

export default CountrySelectorInput;
