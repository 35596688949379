import React, { ReactElement } from 'react';
import { Button, ButtonProps, Icon, useClipboard } from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';

const CopyButton = ({ text, ...rest }: ButtonProps & { text: string }): ReactElement => {
  const { onCopy } = useClipboard(text);
  return (
    <Button
      display="flex"
      alignItems="center"
      height="32px"
      borderRadius="sm"
      minWidth="32px"
      padding="0"
      aria-label="copy"
      variant="ghost"
      onClick={onCopy}
      {...rest}
    >
      <Icon as={MdContentCopy} />
    </Button>
  );
};
export default CopyButton;
