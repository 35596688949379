import {
  Text,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import EmptyState from '../components/EmptyState';
import { MerchantForm } from '../welcome/MerchantForm';

export const MerchantEmptyState = (): React.ReactElement => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <EmptyState>
      <HStack spacing={1}>
        <Text>Please</Text>{' '}
        <Button colorScheme="blue" variant="link" onClick={onOpen}>
          fill business details
        </Button>
        <Text>to get started</Text>
      </HStack>
      <MerchantModal onClose={onClose} isOpen={isOpen} />
    </EmptyState>
  );
};

const MerchantModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent maxWidth="lg">
        <ModalHeader>Enter business details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MerchantForm />
        </ModalBody>
      </ModalContent>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={() => onClose()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
