import { useQuery, UseQueryResult } from 'react-query';
import { managementAPI } from '../utils/api';
import useMe from './useMe';

export interface Credential {
  id: string;
  merchant_id: string;
  tenancy: TenancyType;
  is_revoked: boolean;
  created_at: string;
  updated_at: string;
}
export type TenancyType = 'UAT' | 'SANDBOX' | 'PRODUCTION';

const useCredentials = (merchant_id?: string): UseQueryResult<Credential[], Error> => {
  const { data } = useMe();
  const { merchant } = data || {};
  return useQuery<Credential[], Error>('credentials', getFetchCredentials(merchant?.id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!merchant?.id,
  });
};

const getFetchCredentials = (merchant_id: string | undefined) => {
  return () => {
    return managementAPI.get<Credential[]>(`merchants/${merchant_id}/credentials`).then(res => res.data);
  };
};

export default useCredentials;
