import { Card, InputControl } from '@bureau/components';
import { Box, Button, Flex, HStack, Icon, IconButton, Stack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { MdAdd, MdCheck, MdEdit } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { FormError } from '../components/FormError';
import useMe from '../queries/useMe';
import useUpdateMerchant from '../queries/useUpdateMerchant';

const WebhooksSection = (): ReactElement => {
  const { data } = useMe();
  const { settings } = data || {};
  const { otl_merchant_callback } = settings || {};
  const [showSaveWebhook, setShowSaveWebhook] = React.useState(false);
  return (
    <Flex>
      <Card minWidth="sm">
        <Stack direction="column" spacing={2}>
          <Text fontSize="xl" fontWeight="semibold">
            One Tap Login Webhook
          </Text>
          {otl_merchant_callback && !showSaveWebhook ? (
            <WebhookDisplay setShowSaveWebhook={setShowSaveWebhook} />
          ) : (
            <AddNewWebhook showSaveWebhook={showSaveWebhook} setShowSaveWebhook={setShowSaveWebhook} />
          )}
        </Stack>
      </Card>
    </Flex>
  );
};

const WebhookDisplay = ({
  setShowSaveWebhook,
}: {
  setShowSaveWebhook: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const { data } = useMe();
  const { settings } = data || {};
  const { otl_merchant_callback } = settings || {};
  return (
    <Stack direction="row" align="center">
      <Text fontWeight="bold">Webhook URL:</Text>
      <Text>{otl_merchant_callback}</Text>
      <IconButton
        borderRadius="full"
        aria-label="Edit Webhhok URL"
        icon={<Icon as={MdEdit} />}
        onClick={() => setShowSaveWebhook(true)}
      />
    </Stack>
  );
};
const AddNewWebhook = ({
  showSaveWebhook,
  setShowSaveWebhook,
}: {
  showSaveWebhook: boolean;
  setShowSaveWebhook: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const { data } = useMe();
  const { settings, merchant } = data || {};
  const { otl_merchant_callback } = settings || {};
  const [formError, setFormError] = React.useState<string[] | string | null>(null);
  const queryClient = useQueryClient();
  const id = merchant?.id || '';
  const { mutateAsync } = useUpdateMerchant({
    onSuccess: () => {
      setShowSaveWebhook(false);
      queryClient.refetchQueries('me');
    },
    onError: error => {
      if (error.response) setFormError(error.response.data.errors || error.response.data.message);
    },
  });
  if (showSaveWebhook) {
    return (
      <Formik
        initialValues={{ callbackUrl: otl_merchant_callback || '' }}
        onSubmit={values => {
          return mutateAsync({ id, callbackUrl: values.callbackUrl });
        }}
        validationSchema={Yup.object().shape({
          callbackUrl: Yup.string().required('Callback is required'),
        })}
      >
        {({ isSubmitting, isValid, submitForm }) => {
          return (
            <Form>
              <Stack flexGrow={0} align="flex-start" spacing={4}>
                <Box>
                  <InputControl
                    inputProps={{ autoFocus: true, backgroundColor: 'white', minWidth: '300px' }}
                    name="callbackUrl"
                    placeholder="https://yourendpoint.com/callback"
                  />
                </Box>
                <Button
                  variant="solid"
                  colorScheme="blue"
                  onClick={() => {
                    submitForm();
                  }}
                  isLoading={isSubmitting}
                  isDisabled={!isValid}
                  type="submit"
                  leftIcon={<Icon as={MdCheck} />}
                >
                  Save
                </Button>
                <FormError formError={formError} />
              </Stack>
            </Form>
          );
        }}
      </Formik>
    );
  }
  return (
    <Flex alignItems="center">
      {!otl_merchant_callback && (
        <Button
          variant="link"
          colorScheme="blue"
          onClick={() => {
            setShowSaveWebhook(true);
          }}
        >
          <HStack spacing={0}>
            <Icon as={MdAdd} />
            <Text>Add Webhook</Text>
          </HStack>
        </Button>
      )}
    </Flex>
  );
};

export default WebhooksSection;
