import React, { ReactElement } from 'react';
import { Button, Flex, HStack, HTMLChakraProps, Link, Stack, Text } from '@chakra-ui/react';
import FullLogo from '../Logo/FullLogo';

const PageLoader = ({ children, ...props }: HTMLChakraProps<'div'>): ReactElement => {
  return (
    <Flex direction="column" width="full" height="full">
      <Flex alignItems="flex-start" justifyContent="center" height="full">
        <FullLogo boxProps={{ marginTop: 14, marginLeft: 6 }} width="180px" marginLeft={4} />
        <Stack marginTop="62px" spacing={5} marginLeft={10} marginRight="auto" maxWidth="900px">
          {children}
        </Stack>
      </Flex>
      <HStack
        flexShrink={0}
        width="100%"
        backgroundColor="gray.200"
        height={10}
        color="gray.900"
        alignItems="center"
        padding={4}
        fontSize="sm"
        justifyContent="space-between"
        alignSelf="flex-end"
      >
        <Text>© Bureau Inc 2021</Text>
        <Link color="blue.500" href="https://bureau.id">
          bureau.id
        </Link>
      </HStack>
    </Flex>
  );
};
export default PageLoader;
