import { HStack, Stack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import EndpointsList from '../product/EndpointsList';
import { ProductInfo } from '../product/ProductInfo';
import { ProductLogo } from '../product/ProductLogo';
import QuickLinks from '../product/QuickLinks';
import { TryOutSection } from './TryOut';

const BureauOnboardProduct = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>Bureau OnBoard - Bureau Dashboard</title>
      </Helmet>
      <Stack spacing="5">
        <HStack width="100%" justifyContent="space-between">
          <HStack spacing="6">
            <ProductLogo product="OnBoard" />
            <Stack>
              <ProductInfo product="OnBoard" />
              <EndpointsList product="OnBoard" />
            </Stack>
          </HStack>
          <QuickLinks product="OnBoard" />
        </HStack>
        <TryOutSection />
      </Stack>
    </>
  );
};

export default BureauOnboardProduct;
