/* eslint-disable @typescript-eslint/camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

const ProtectedRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated } = useAuth0();
  const currentLocation = useLocation();

  if (isAuthenticated) {
    return <Route {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          from: currentLocation,
        },
      }}
    />
  );
};
export default ProtectedRoute;
