import { Card } from '@bureau/components';
import { Circle, Progress, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { formatDistance } from 'date-fns/esm';
import React, { ReactElement } from 'react';
import EmptyState from '../components/EmptyState';
import useActiveSubscriptions from '../queries/useActiveSubscriptions';
import useSubscriptions, { Subscription } from '../queries/useSubscriptions';

const PostPaidUsage = (): ReactElement => {
  const activeSubscriptions = useActiveSubscriptions();
  let usage = 0;
  activeSubscriptions.forEach(subscription => {
    usage += subscription.usage;
  });
  const updatedDate = formatDistance(new Date(activeSubscriptions[0].updated_at), new Date());
  return (
    <Card flexShrink={0}>
      <Stack flexGrow={1}>
        <Text color="gray.800" fontSize="2xl" fontWeight="semibold">
          Usage
        </Text>

        <Stack direction={['column', 'row']} align={['flex-start', 'center']}>
          <Stack direction="row" align="center">
            <Circle bg="green.500" size="16px" />
            <Text>{usage} used</Text>
          </Stack>
          <Text textAlign="right" color="gray.500" fontSize="sm">
            Updated {updatedDate} ago
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};
const Usage = (): ReactElement => {
  const activeSubscriptions = useActiveSubscriptions();
  if (!activeSubscriptions.length) {
    return <></>;
  }
  let usage = 0;
  let quota = 0;
  let percentage = 0;
  let dates: string[] = [];
  let lastDate = activeSubscriptions[0].valid_upto;
  activeSubscriptions.forEach(subscription => {
    usage += subscription.usage;
    quota += subscription.quota;
    if (Date.parse(subscription.valid_upto) > Date.parse(lastDate)) {
      lastDate = subscription.valid_upto;
    }
    dates = [...dates, subscription.valid_upto];
  });
  if (usage && quota && quota !== 0) percentage = (usage / quota) * 100;
  const isPostPaid = activeSubscriptions[0].type === 'POSTPAID';
  if (isPostPaid) {
    return <PostPaidUsage />;
  }
  const lastDateFormatted = format(new Date(lastDate), 'MMM do yyyy');
  const updatedDate = formatDistance(new Date(activeSubscriptions[0].updated_at), new Date());
  return (
    <Card flexShrink={0}>
      <Stack flexGrow={1}>
        <Text color="gray.800" fontSize="2xl" fontWeight="semibold">
          Usage
        </Text>
        <Stack width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Stack spacing={1} direction="row">
              <Text fontWeight="semibold">{percentage.toFixed(2)}%</Text>
              <Text>used</Text>
            </Stack>
            <Text textAlign="right" color="gray.500" fontSize="sm">
              Updated {updatedDate} ago
            </Text>
          </Stack>
          <Text fontSize="sm" fontWeight="semibold" color="gray.500">
            Usage expires on {lastDateFormatted}
          </Text>
          <Progress colorScheme="green" borderRadius={4} size="md" value={percentage} width="100%" />
          <Stack direction={['column', 'row']} align={['flex-start', 'center']}>
            <Stack direction="row" align="center">
              <Circle bg="green.500" size="16px" />
              <Text>{usage} used</Text>
            </Stack>
            {!isPostPaid && (
              <Stack direction="row" align="center">
                <Circle bg="gray.200" size="16px" />
                <Text>{quota - usage} remaining</Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
const ActiveSubscriptions = (): ReactElement => {
  const activeSubscriptions = useActiveSubscriptions();
  if (!activeSubscriptions.length) {
    return <EmptyState>No active plans</EmptyState>;
  }
  return (
    <Card>
      <Stack spacing={2} width="full">
        <Text color="gray.800" fontSize="xl" fontWeight="semibold">
          Active plans
        </Text>
        {activeSubscriptions.map(subscription => {
          const { usage, quota, created_at, valid_upto } = subscription;
          return (
            <Stack key={subscription.id}>
              <Text fontSize="sm">Purchased on {format(new Date(created_at), 'MMM do yyyy')}</Text>
              <Stack direction={['column', 'column', 'row']} justifyContent="space-between">
                <Stack direction={['column', 'row']} align={['flex-start', 'center']}>
                  <Stack direction="row" align="center">
                    <Circle bg="green.500" size="10px" />
                    <Text whiteSpace="nowrap" color="gray.500" fontSize="sm">
                      {usage} used
                    </Text>
                  </Stack>
                  {subscription.type === 'PREPAID' && (
                    <Stack direction="row" align="center">
                      <Circle bg="gray.200" size="10px" />
                      <Text whiteSpace="nowrap" color="gray.500" fontSize="sm">
                        {quota - usage} remaining
                      </Text>
                    </Stack>
                  )}
                </Stack>
                {valid_upto && (
                  <Text textAlign={['left', 'left', 'right']} color="gray.500" fontSize="sm">
                    Valid till {format(new Date(valid_upto), 'MMM do yyyy')}
                  </Text>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
};
const SubscriptionDetail = ({ subscription }: { subscription: Subscription }): ReactElement => {
  return (
    <Stack spacing="1">
      <Stack direction="row" align="center">
        <Text fontSize="md" color="gray.800">
          Purchased on {format(new Date(subscription.created_at), 'MMM do yyyy')}
        </Text>
        {subscription.status === 'EXPIRED' ||
          (subscription.status === 'EXHAUSTED' && (
            <Text fontSize="sm" color="gray.500" bgColor="gray.100" paddingX="1" paddingY="0">
              {subscription.status}
            </Text>
          ))}
      </Stack>
      {subscription.valid_upto && (
        <Text fontSize="sm" color="gray.500">
          Valid till: {format(new Date(subscription.valid_upto), 'MMM do yyyy')}
        </Text>
      )}
    </Stack>
  );
};
const SubscriptionHistory = (): ReactElement => {
  const { data: subscriptions } = useSubscriptions();
  if (!subscriptions) {
    return <></>;
  }
  return (
    <Card flexGrow={1}>
      <Stack spacing="4">
        <Text fontSize="xl" fontWeight="bold">
          Plans
        </Text>
        {subscriptions.map((subscription: Subscription) => {
          return <SubscriptionDetail key={subscription.id} subscription={subscription} />;
        })}
      </Stack>
    </Card>
  );
};
export const SusbscriptionSection = (): ReactElement => {
  return (
    <Stack direction={['column', 'row', 'row', 'row']} spacing={8}>
      <Stack width={['100%', '50%']} direction="column" spacing={4}>
        <Usage />
        <ActiveSubscriptions />
      </Stack>
      <SubscriptionHistory />
    </Stack>
  );
};
