import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const TRY_OUT_BASE_URL = process.env.REACT_APP_BUREAU_BASE_URL;
const METRICS_BASE_URL = process.env.REACT_APP_METRICS_BASE_URL;

const managementAPI = axios.create({
  baseURL: BASE_URL,
});

const metricsAPI = axios.create({
  baseURL: METRICS_BASE_URL,
});

const tryOutAPI = axios.create({
  baseURL: TRY_OUT_BASE_URL,
});

const setupAuth = (token: string): void => {
  metricsAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
  managementAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
  tryOutAPI.defaults.headers.common['AuthorizationToken'] = `Bearer ${token}`;
};

export { managementAPI, metricsAPI, tryOutAPI, setupAuth };
