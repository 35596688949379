import useProductList from '../queries/useProductList';

type SubItemType = { to: string; title: string };
const homeSubItems = {
  OTL: {
    to: '/products/onetap',
    title: 'One tap login',
  },
  OnBoard: {
    to: '/products/onboard',
    title: 'Bureau Onboard',
  },
} as { [key: string]: SubItemType };

const useSidebarHomeSubItems = (): Array<SubItemType> => {
  const productList = useProductList();
  return productList.map(product => {
    return homeSubItems[product];
  });
};

export default useSidebarHomeSubItems;
