import { PageLoader } from '@bureau/components';
import { Flex } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../home/Home';
import ProductLanding from '../product/ProductLanding';
import QueryClientProvider from '../queries/QueryClientProvider';
import useMe, { MeResponse } from '../queries/useMe';
import AppWelcome from '../welcome/AppWelcome';
import AuthCallback from './AuthCallback';
import NotFound from './NotFound';
import { PageHeader } from './PageHeader';
import { PageSidebar } from './PageSidebar';
import Settings from './Settings';

const userHasData = (data: MeResponse | undefined): boolean => {
  const { user } = data || {};
  return !!(user?.name && user?.email && user?.phone) || false;
};
const AppLandingContent = (): ReactElement => {
  const { data, status, error } = useMe();
  if (status === 'loading') {
    return <PageLoader>Loading</PageLoader>;
  }
  if (status === 'error' && error) {
    return <>{error.message}</>;
  }
  if (!userHasData(data)) {
    return <AppWelcome />;
  }
  return (
    <Flex flexDirection="column" height="full" backgroundColor="#f9f9f9">
      <PageHeader />
      <Flex flexDirection="row" flexGrow={1} height="full" paddingLeft="12">
        <PageSidebar />
        <Switch>
          <Route exact path="/callback" component={AuthCallback} />
          <Route path="/settings" component={Settings} />
          <Route path="/products" component={ProductLanding} />

          <Route exact path="/" component={Home} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Flex>
    </Flex>
  );
};
const AppLanding = (): ReactElement => {
  return (
    <QueryClientProvider>
      <AppLandingContent />
    </QueryClientProvider>
  );
};

export default AppLanding;
