import React, { ReactElement } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthRedirectHandler from './AuthRedirectHandler';
import history from '../utils/history';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const onRedirectCallback = (appState?: { returnTo?: string; signup_flow?: boolean }): void => {
  // Use the router's history module to replace the url
  if (appState?.signup_flow) {
    history.replace('/');
    return;
  }
  history.replace(appState?.returnTo || '/');
};

const AuthenticationProvider = ({ children }: { children: React.ReactNode }): ReactElement => {
  const redirectUri = `${window.location.origin}/callback`;

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={AUTH0_AUDIENCE}
      useRefreshTokens
    >
      <AuthRedirectHandler>{children}</AuthRedirectHandler>
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
