import { Card, PageLoader } from '@bureau/components';
import { Stack, Table, TableCaption, Tbody, Text, Th, Thead, Tr, Td } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import useCredentials from '../queries/useCredentials';
import useMe from '../queries/useMe';
import { CreateCredentialButton } from './CreateCredentialButton';
import EmptyState from '../components/EmptyState';
import { Endpoint } from '../components/Endpoint';

const CredentialsSection = (): ReactElement => {
  const { data, status: merchantStatus } = useMe();
  const { merchant } = data || {};
  const { data: credentials, status, error } = useCredentials(merchant?.id);
  if (status === 'loading' || merchantStatus === 'loading') {
    return <PageLoader>Loading</PageLoader>;
  }
  if (status === 'error' && error && (error as AxiosError).response?.status !== 404) {
    return <>{error.message}</>;
  }
  if (!merchant) {
    return <EmptyState>Please fill business details before generating credentials</EmptyState>;
  }
  const prodCredentials = credentials?.filter(credential => {
    return credential.tenancy === 'PRODUCTION';
  });
  return (
    <Stack spacing="4">
      <Stack direction="row" justifyContent="space-between" align="center">
        <Endpoint marginLeft={4} endpoint={{ title: 'Endpoint', url: 'https://api.bureau.id/v1', type: 'prod' }} />
        <CreateCredentialButton>Generate new key</CreateCredentialButton>
      </Stack>
      <Card paddingX={0}>
        <Table variant="simple">
          {!credentials && (
            <TableCaption>
              <Stack>
                <Text>No keys found</Text>
              </Stack>
            </TableCaption>
          )}
          <Thead>
            <Tr>
              <Th>Key ID</Th>
              <Th>Created at</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {prodCredentials?.map(credential => {
              const { id, created_at, is_revoked } = credential;
              return (
                <Tr key={id}>
                  <Td>{id}</Td>
                  <Td>{format(new Date(created_at), 'MMM do yyyy, hh:mm aaa')}</Td>
                  <Td>{is_revoked ? 'Revoked' : 'Active'}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </Stack>
  );
};

export default CredentialsSection;
