import { PageLoader } from '@bureau/components';
import { Heading, Stack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import useMe from '../queries/useMe';

const PageWrap = ({ children, heading }: { children: React.ReactNode; heading?: React.ReactNode }): ReactElement => {
  const { status } = useMe();
  if (status === 'loading') {
    return <PageLoader>Loading</PageLoader>;
  }
  return (
    <Stack height="calc(100% - 64px)" overflow="auto" flexGrow={1} spacing={4} flexDirection="column" padding={4}>
      {heading && <Heading>{heading}</Heading>}
      {children}
    </Stack>
  );
};

export default PageWrap;
