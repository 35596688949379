import { Flex, FlexProps, useStyleConfig } from '@chakra-ui/react';
import * as React from 'react';

export type CardVariantType = 'border' | 'shadow';
const Card = ({ variant, ...props }: FlexProps & { variant?: CardVariantType }): React.ReactElement => {
  const styles = useStyleConfig('Card', { variant });
  return <Flex __css={styles} {...props} />;
};

export default Card;
