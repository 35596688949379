import { OptionType, SelectInput } from '@bureau/components/src';
import { Stack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { TryOutEmail } from './TryOutEmail';
import { TryOutIPAddress } from './TryOutIPAddress';
import { TryOutPhoneNetwork } from './TryOutPhoneNetwork';

const options = [
  { value: 'phoneNetwork', label: 'Phone network' },
  { value: 'email', label: 'Email' },
  { value: 'ipAddress', label: 'IP address' },
];

const selectedApiToComponent = {
  phoneNetwork: TryOutPhoneNetwork,
  email: TryOutEmail,
  ipAddress: TryOutIPAddress,
} as { [key: string]: () => ReactElement };
export const SignalsTryOut = (): ReactElement => {
  const [selectedApi, setSelectedApi] = React.useState<string>('phoneNetwork');
  const Component = selectedApiToComponent[selectedApi];
  return (
    <Stack align="flex-start" spacing="4">
      <Stack paddingLeft="4">
        <Text fontSize="lg">Choose option</Text>
        <SelectInput
          isClearable={false}
          width="300px"
          name="selectedApi"
          options={options}
          value={selectedApi}
          menuWidth="300px"
          onChange={value => {
            const finalValue = (value as OptionType)?.value;
            setSelectedApi(finalValue);
          }}
        />
      </Stack>
      <Stack alignSelf="stretch">
        <Component />
      </Stack>
    </Stack>
  );
};
