import {
  forwardRef,
  HStack,
  HTMLChakraProps,
  omitThemingProps,
  Progress,
  Stack,
  Text,
  ThemingProps,
} from '@chakra-ui/react';
import * as React from 'react';

export interface StepProps extends HTMLChakraProps<'div'>, ThemingProps {
  activeStep: number;
  stepIndex: number;
  label: string;
}

const Step = forwardRef<StepProps, 'div'>((props, ref: React.Ref<HTMLDivElement>) => {
  const { stepIndex, label } = omitThemingProps(props);

  return (
    <Stack flexGrow={1}>
      <HStack flexGrow={1} flexShrink={0} justifyContent="center" spacing={1}>
        <Text>{stepIndex + 1}.</Text>
        <Text>{label}</Text>
      </HStack>
    </Stack>
  );
});

Step.displayName = 'Step';

export { Step };
