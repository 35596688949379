import React, { ReactElement } from 'react';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { InputControl } from '@bureau/components';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import useCreateMerchant from '../queries/useCreateMerchant';
import useMe from '../queries/useMe';
import { FormError } from '../components/FormError';
import PhoneControl from './PhoneControl';

export const MerchantForm = (): ReactElement => {
  const queryClient = useQueryClient();
  const { data } = useMe();
  const { user } = data || {};
  const [formError, setFormError] = React.useState<string[] | string | null>(null);
  const { mutateAsync } = useCreateMerchant({
    onSuccess: () => {
      queryClient.refetchQueries('me');
    },
    onError: error => {
      if (error.response) setFormError(error.response.data.errors || error.response.data.message);
    },
  });
  const isFetchingMerchant = queryClient.isFetching('me');
  const defaultCountryCode = 'IN';
  if (!user) {
    return <></>;
  }
  return (
    <Formik
      isInitialValid={false}
      initialValues={{
        name: '',
        website: '',
        email: '',
        phone: '',
        address: '',
        country_code: defaultCountryCode,
        owner_id: user.id,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('No email provided'),
        name: Yup.string().required('No name provided'),
        address: Yup.string().required('No address provided'),
        website: Yup.string().required('No website provided'),
        phone: Yup.string().required('No phone number provided'),
      })}
      onSubmit={async (values): Promise<void> => {
        try {
          setFormError(null);
          return mutateAsync({ ...values, phone: values.phone.substring(1) });
        } catch (error) {
          setFormError(error);
          return Promise.resolve();
        }
      }}
    >
      {({ isSubmitting, values, isValid }) => {
        return (
          <Form>
            <Stack paddingTop={4} spacing={4} align="flex-start">
              <Stack width="100%" direction="row">
                <InputControl name="name" label="Name" placeholder="Enter name" />
                <InputControl name="email" label="E-mail" placeholder="info@business.com" />
              </Stack>
              <Stack width="100%" direction="row">
                <WelcomePhoneControl />
                <InputControl name="website" label="Website" placeholder="https://website.com" />
              </Stack>
              <Stack width="100%" direction="row">
                <InputControl name="address" label="Address" placeholder="Enter address" />
              </Stack>
              {values.country_code === 'IN' && (
                <Stack direction="row" width="50%">
                  <InputControl name="gst" label="GST Number" placeholder="Enter GST number" />
                </Stack>
              )}
              <Flex direction="row" width="full">
                <FormError formError={formError} />
                <Button
                  isDisabled={!isValid}
                  marginLeft="auto"
                  type="submit"
                  isLoading={isSubmitting || !!isFetchingMerchant}
                  colorScheme="blue"
                  isFullWidth={false}
                >
                  Continue
                </Button>
              </Flex>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

const WelcomePhoneControl = (): ReactElement => {
  return (
    <PhoneControl countryCodeFieldName="country_code" name="phone" label="Phone" placeholder="Enter phone number" />
  );
};
