import { Flex, Image } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { productMap } from '../home/productUtil';

export const ProductLogo = ({ product }: { product: string }): ReactElement => {
  const { logo } = productMap[product];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      backgroundColor="gray.100"
      borderRadius="full"
      height="120px"
      width="120px"
      padding={4}
      flexShrink={0}
    >
      <Image height="80%" src={logo} />
    </Flex>
  );
};
