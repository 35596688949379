import React, { ReactElement } from 'react';
import { Stack, StackProps, Text } from '@chakra-ui/react';
import { CopyButton } from '@bureau/components';
import { EndpointType } from '../home/productUtil';

export const Endpoint = ({ endpoint, ...props }: StackProps & { endpoint: EndpointType }): ReactElement => {
  return (
    <Stack spacing="0" {...props}>
      <Text color="gray.500" fontSize="sm" fontWeight="bold">
        {endpoint.title}
      </Text>
      <Stack direction="row" align="center">
        <Text fontSize="md">{endpoint.url}</Text>
        <CopyButton text={endpoint.url} />
      </Stack>
    </Stack>
  );
};
