import { InputControl, InputControlProps } from '@bureau/components';
import { InputLeftAddon } from '@chakra-ui/react';
import React from 'react';

export const InputPhoneControl = (props: InputControlProps): React.ReactElement => {
  return (
    <InputControl
      {...props}
      paddingLeft="4"
      paddingTop="4"
      width="200px"
      minWidth="33%"
      inputProps={{ backgroundColor: 'white' }}
      label="Phone"
      placeholder="Phone"
      leftChildren={<InputLeftAddon>+91</InputLeftAddon>}
    />
  );
};
