import React from 'react';
import { Button, Stack } from '@chakra-ui/react';
import { InputControl } from '@bureau/components';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import useUpdateUser from '../queries/useUpdateUser';
import useMe from '../queries/useMe';
import { FormError } from '../components/FormError';
import PhoneControl from './PhoneControl';

export const UserForm = (): React.ReactElement => {
  const { data } = useMe();
  const { user } = data || {};
  const [formError, setFormError] = React.useState<string[] | string | null>(null);
  const queryClient = useQueryClient();
  const isFetchingUser = queryClient.isFetching('me');
  const { mutateAsync } = useUpdateUser({
    onSuccess: () => {
      queryClient.refetchQueries('me');
    },
    onError: error => {
      if (error.response) setFormError(error.response.data.errors || error.response.data.message);
    },
  });
  if (!user) {
    return <></>;
  }
  return (
    <Formik
      initialValues={{
        name: user?.name || '',
        phone: user?.phone || '',
      }}
      onSubmit={(values): void => {
        // update user API
        try {
          setFormError(null);
          mutateAsync({ ...values, phone: values.phone.substring(1), id: user?.id });
        } catch (error) {
          setFormError(error);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Stack paddingTop={4} spacing={4}>
              <InputControl name="name" label="Your name" placeholder="Your name" />
              <PhoneControl
                countryCodeFieldName="country_code"
                name="phone"
                label="Phone number"
                placeholder="Your phone number"
              />
              <Button isLoading={isSubmitting || !!isFetchingUser} colorScheme="blue" type="submit">
                Next
              </Button>
              <FormError formError={formError} />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
