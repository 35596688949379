import { Link, Stack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { productMap, QuickLinkType } from '../home/productUtil';

const QuickLink = ({ title, url, external }: QuickLinkType): ReactElement => {
  if (external) {
    return (
      <Link
        href={url}
        fontSize="sm"
        fontWeight="semibold"
        color="gray.500"
        _hover={{
          color: 'gray.600',
        }}
        textDecoration="underline"
      >
        {title}
      </Link>
    );
  }
  return (
    <Link
      as={RouterLink}
      to={url}
      fontSize="sm"
      fontWeight="semibold"
      color="gray.500"
      _hover={{
        color: 'gray.600',
      }}
      textDecoration="underline"
    >
      {title}
    </Link>
  );
};
export const QuickLinks = ({ product }: { product: string }): ReactElement => {
  const { quickLinks } = productMap[product];
  if (!quickLinks) {
    return <></>;
  }
  const isSingleLink = quickLinks.length === 1;
  return (
    <Stack
      direction="column"
      spacing="0"
      textAlign={['left', 'left', 'right', 'right']}
      paddingLeft={[0, '136px', 0, 0]}
    >
      {!isSingleLink && (
        <Text color="gray.500" fontSize="sm" fontWeight="bold">
          Quick links
        </Text>
      )}
      {quickLinks.map(quickLink => {
        return <QuickLink key={quickLink.url} {...quickLink} />;
      })}
    </Stack>
  );
};
export default QuickLinks;
