import { extendTheme } from '@chakra-ui/react';
import { baseTheme } from '@bureau/components';

const theme = extendTheme(
  {
    styles: {
      global: {
        'html, body, #root': {
          height: 'full',
          width: 'full',
          overflow: 'hidden',
        },
      },
    },
  },
  baseTheme,
);

export default theme;
